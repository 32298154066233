import { FC } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import {
  UserOutlined,
  LogoutOutlined,
  LayoutOutlined,
  SettingFilled,
  CheckCircleFilled,
  GlobalOutlined,
} from "@ant-design/icons";
import { AuthType } from "hooks/useUserAuth";

interface Props {
  auth: AuthType;
}

export const PrimaryNavigation: FC<Props> = ({ auth }) => {
  return (
    <Menu
      defaultSelectedKeys={["gallerSub"]}
      mode="inline"
      style={{ background: "none" }}
    >
      <Menu.Item key="6" style={{ borderRadius: 0 }} icon={<LayoutOutlined />}>
        <Link to="/gallery">Galleries</Link>
      </Menu.Item>
      {auth?.userData?.isAdmin && (
        <Menu.Item key="2" style={{ borderRadius: 0 }} icon={<UserOutlined />}>
          <Link to="/users">Users</Link>
        </Menu.Item>
      )}
      {auth?.userData?.isAdmin && (
        <Menu.Item
          key="3"
          style={{ borderRadius: 0 }}
          icon={<GlobalOutlined />}
        >
          <Link to={`/site/${auth?.userData?._id}`}>Web Site</Link>
        </Menu.Item>
      )}
      {/*
      <Menu.Item
        key="4"
        style={{ borderRadius: 0 }}
        icon={<NotificationOutlined />}
      >
        <Link to="/email">Campaigns</Link>
      </Menu.Item> */}
      <Menu.Divider></Menu.Divider>
      <Menu.Item
        key="44"
        icon={<CheckCircleFilled />}
        style={{ borderRadius: 0 }}
      >
        <Link to="/subscription">Subscription</Link>
      </Menu.Item>
      <Menu.Item key="444" icon={<SettingFilled />} style={{ borderRadius: 0 }}>
        <Link to="/account">Settings</Link>
      </Menu.Item>
      <Menu.Item key="7" onClick={auth.logout} icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );
};
