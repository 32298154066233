import { GalleryDocument } from "views/Gallery/data/service";
import { getImgURLs } from "../utils";
import { Link } from "react-router-dom";
import { FC } from "react";
import "./GalleryList2.css";
import { Button, notification } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useIsMobile } from "hooks/useIsMobile";
import { UseDeleteGallery } from "views/Gallery/data/useGallery";
import { GalleryActionItems } from "./GalleryActionItems";

type GalleryListImageProps = {
  imgUrl: string;
};

const GalleryListImage: FC<GalleryListImageProps> = ({ imgUrl }) => {
  return (
    <div
      style={{
        background: `url("${imgUrl}")`,
        width: "50%",
        height: 100,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>
  );
};

type GalleryListAProps = {
  data?: GalleryDocument[];
};

export const GalleryListB: FC<GalleryListAProps> = ({ data }) => {
  const { mutate, isPending } = UseDeleteGallery();
  const [api, contextHolder] = notification.useNotification();
  const isMobile = useIsMobile();

  const openNotification = () => {
    api.info({
      message: "Gallery Deleted",
      // description:
      //   "This is the content of the notification. This is the content of the notification. This is the content of the notification.",
      placement: "bottom",
    });
  };

  const handleDeleteGalleryClick = (galleryId: string) => {
    mutate({ galleryId });
    openNotification();
  };

  return (
    <div className="gallery-list">
      {contextHolder}
      <div
        className={isMobile ? "gallery-add-new-item" : "gallery-list-item"}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
        }}
      >
        <Link to="/gallery/new">
          <Button icon={<PlusCircleOutlined />} size="large" type="primary">
            Create New Gallery
          </Button>
        </Link>
      </div>
      {data?.map((i) => {
        return (
          <div className="gallery-list-item">
            <div className="gallery-list-item-menu">
              <h2>
                <Link to={`/gallery/${i._id}`}>{i.name}</Link>
              </h2>

              <GalleryActionItems
                galleryId={i._id}
                handleDelete={handleDeleteGalleryClick}
                isDeletePending={isPending}
              />
            </div>
            <div className="gallery-list-item-img-container">
              {getImgURLs(i.images).map((url) => (
                <GalleryListImage imgUrl={url} />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};
