import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  GalleryDocument,
  create,
  get,
  put,
  destroy,
  destroyImage,
  list,
} from "./service";

export const UseListAllGalleries = () => {
  return useQuery({ queryKey: ["fetchAllGalleries"], queryFn: () => list() });
};

export const UseFetchGallery = (galleryId?: string) => {
  return useQuery({
    queryKey: galleryId ? ["fetchGallery", galleryId] : ["fetchGallery"],
    queryFn: () => get(galleryId),
    enabled: !!galleryId,
  });
};

export const UseCreateNewGallery = () => {
  return useQuery({
    queryKey: ["createNewGallery"],
    queryFn: () => create(),
  });
};

type GalleryDataMutationType = { id: string; data: GalleryDocument };

export const UseGalleryInfoMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (vars: GalleryDataMutationType) => put(vars.id, vars.data),
    onSuccess: (data, vars) => {
      queryClient.invalidateQueries({ queryKey: ["fetchGallery", vars.id] });
      queryClient.invalidateQueries({ queryKey: ["fetchAllGalleries"] });
    },
    onError: (error) => {
      console.error("Error updating gallery info:", error);
    },
  });
};

type UseDeleteGalleryMutation = {
  galleryId: string;
};

export const UseDeleteGallery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (vars: UseDeleteGalleryMutation) => destroy(vars.galleryId),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ["fetchAllGalleries"] });
    },
    onError: (error) => {
      console.error("Error deleting gallery:", error);
    },
  });
};

type GalleryImageDeleteMutation = {
  galleryId: string;
  imgId: string;
};

export const UseGalleryImageDeleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (vars: GalleryImageDeleteMutation) =>
      destroyImage(vars.galleryId, vars.imgId),
    onSuccess: (data, vars) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchGallery", vars.galleryId],
      });
    },
    onError: (error) => {
      console.error("Error deleting gallery image:", error);
    },
  });
};
