import { UseGalleryImageDeleteMutation } from "../data/useGallery";
import { GalleryItems } from "./GalleryItems";
import { GalleryInfoForm } from "./GalleryInfoForm";
import { GalleryImageUpload } from "./GalleryUpload";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./index.css";
import { Drawer, Menu, MenuProps } from "antd";
import { Link } from "react-router-dom";
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { ShareGallery } from "../share";
import { ImageDetail } from "./ImageDetail";

export const Gallery = () => {
  const { galleryId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { mutate } = UseGalleryImageDeleteMutation();
  const handleOnImageDelete = (galleryId: string, imgId: string) => {
    mutate({ galleryId, imgId });
    navigate(`/gallery/${galleryId}`);
  };

  type MenuItem = Required<MenuProps>["items"][number];
  const menuItems: MenuItem[] = [
    {
      label: <Link to="/gallery">Back</Link>,
      key: "back",
      icon: <ArrowLeftOutlined />,
    },
    {
      label: <Link to={`details`}>Settings</Link>,
      key: "edit",
      icon: <EditOutlined />,
    },
    {
      label: <Link to={`share`}>Share</Link>,
      key: "share",
      icon: <ShareAltOutlined />,
    },
    {
      label: <Link to="">Delete</Link>,
      key: "delete",
      icon: <DeleteOutlined />,
    },
  ];

  return (
    <>
      <Menu
        mode="horizontal"
        items={menuItems}
        style={{ marginBottom: "25px" }}
      />
      <GalleryImageUpload galleryId={galleryId} />
      <GalleryItems galleryId={galleryId} />
      <Drawer
        placement="right"
        open={location.pathname.includes("/details")}
        onClose={() => navigate(location.pathname.replace("/details", ""))}
        size="large"
        destroyOnClose
      >
        <GalleryInfoForm
          galleryId={galleryId}
          onFinishPath={`/gallery/${galleryId}`}
        />
      </Drawer>
      <ShareGallery onClosePath={`/gallery/${galleryId}`} />
      <Drawer
        placement="right"
        open={location.pathname.includes("/image")}
        onClose={() => navigate(`/gallery/${galleryId}`)}
        size="large"
        destroyOnClose
      >
        <ImageDetail onDelete={handleOnImageDelete} />
      </Drawer>
    </>
  );
};
