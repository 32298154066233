import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCurrentUser } from "services/user";
import { update, create } from "services/user";
import { CreateUserType, UserType } from "types/User";
export const UseFetchUser = () => {
  return useQuery({
    queryKey: ["fetchUser"],
    queryFn: getCurrentUser,
  });
};

type UserMutationProps = {
  data: Partial<UserType>;
};

export const UseUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (vars: UserMutationProps) => update(vars.data),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ["fetchUser"] });
    },
  });
};

type UserRegisterUserProp = {
  data: CreateUserType;
};
export const UseRegisterUser = () => {
  return useMutation({
    mutationFn: (vars: UserRegisterUserProp) => create(vars.data),
  });
};
