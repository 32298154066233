import { FC } from "react";
import { Form, Input, Button, Spin } from "antd";
import { UseRegisterUser } from "hooks/useUser";
import { CreateUserType } from "../../types/User";
import { Link, useNavigate } from "react-router-dom";
import "./index.css";

export const SignUpForm: FC = () => {
  const { mutate, isPending, isError, isSuccess } = UseRegisterUser();
  const navigate = useNavigate();

  const onFinish = async (data: CreateUserType) => {
    mutate({ data });
  };

  const onFailed = (e: any) => {
    console.log(e);
  };

  if (isSuccess) {
    navigate("/");
  }

  if (isError) {
    return <p>Oof. Server error.</p>;
  }

  return (
    <>
      <h2 className="cactus-classical-serif-regular">Gallery Storm</h2>
      <h4>Create your free account</h4>
      <div style={{ fontSize: ".8em", color: "#aaa" }}>
        Already have an account? <Link to="/">Log in</Link>
      </div>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFailed}
        autoComplete="off"
        className="login-form"
      >
        <Form.Item
          label="Business Name"
          name="businessName"
          rules={[{ required: true, message: "Please input your username" }]}
        >
          <Input className="login-input" />
        </Form.Item>
        <Form.Item label="Website" name="website">
          <Input className="login-input" />
        </Form.Item>
        <Form.Item
          label="Email Address"
          name="email"
          rules={[
            {
              type: "email",
              required: true,
              message: "Please input a valid email address",
            },
          ]}
        >
          <Input className="login-input" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password" }]}
        >
          <Input.Password className="login-input" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={isPending}
            style={{ width: "100%" }}
          >
            {isPending ? <Spin /> : "CREATE ACCOUNT"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
