import { Button, Skeleton } from "antd";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { buildStaticURL } from "utils";
import { UseFetchGallery } from "views/Gallery/data/useGallery";

type ImageDetailProps = {
  onDelete: (galleryId: string, imgId: string) => void;
};
export const ImageDetail: FC<ImageDetailProps> = ({ onDelete }) => {
  const { galleryId, imgId } = useParams();
  const { data, isLoading } = UseFetchGallery(galleryId);
  const image = data?.images?.find((i) => i.id === imgId);

  const handleDelete = () => {
    onDelete(galleryId || "", imgId || "");
  };

  if (isLoading) {
    return <Skeleton />;
  }
  // @ts-ignore
  const exif = image?.exif?.exif;
  return (
    <>
      <img
        src={buildStaticURL(image?.url.l)}
        style={{ maxWidth: "100%" }}
        alt={imgId}
      />

      <Button onClick={handleDelete}>Delete</Button>

      <h3>EXIF</h3>
      {exif && (
        <table>
          {Object.keys(exif).map((key) => (
            <tr>
              <td style={{ textAlign: "right" }}>
                {key}
                {"  "}
              </td>
              <td>{exif[key]}</td>
            </tr>
          ))}
        </table>
      )}
    </>
  );
};
