import { useIsMobile } from "hooks/useIsMobile";
import { ImageDocument } from "./data/service";
import { FC } from "react";
import { buildStaticURL } from "utils";
import { Image } from "antd";

const API_URI = process.env.REACT_APP_API_URL;

function buildMultidimensionalArray(arr: ImageDocument[]) {
  const arrays = [[], [], [], []];
  for (let i = 0; i < arr.length; i++) {
    // @ts-ignore
    arrays[i % 4].push(arr[i]);
  }

  return arrays;
}

type GalleryImageProps = {
  imgData: ImageDocument;
  galleryId?: string | undefined;
};
const GalleryImage: FC<GalleryImageProps> = ({ imgData, galleryId }) => {
  return (
    <div className="grid-item">
      <Image
        src={buildStaticURL(imgData.url.s)}
        style={{ width: "100%" }}
        width={"100%"}
        alt={galleryId}
        preview={{
          src: buildStaticURL(imgData.url.l),
        }}
      />
    </div>
  );
};

const MobileGaleryImage: FC<GalleryImageProps> = ({ imgData, galleryId }) => {
  return (
    <div className="grid-item">
      <a href={`${API_URI}/download?fileName=${imgData.url.l}`}>
        <img src={buildStaticURL(imgData.url.s)} />
      </a>
    </div>
  );
};
type ImageLayoutType = {
  images: ImageDocument[];
  galleryId?: string | undefined;
};
// @ts-ignore
export const ImageLayout: FC<ImageLayoutType> = ({ images, galleryId }) => {
  const isMobile = useIsMobile();

  // @ts-ignore
  // return images?.map((item: ImageDocument, index: number) => (
  //   // @ts-ignore
  //   <MobileGaleryImage imgData={item} galleryId={galleryId} />
  // ));

  return (
    <>
      {isMobile
        ? // @ts-ignore
          images?.map((item: ImageDocument, index: number) => (
            <MobileGaleryImage imgData={item} galleryId={galleryId} />
          ))
        : buildMultidimensionalArray(images).map((column) => (
            <div className="grid-column">
              {column.map((item: ImageDocument, index: number) => (
                <GalleryImage
                  key={item.id}
                  imgData={item}
                  galleryId={galleryId}
                />
              ))}
            </div>
          ))}
    </>
  );
};
