import { ImageDocument } from "../data/service";

const STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;

export const getImgURLs = (images?: ImageDocument[]) => {
  if (!images || !Array.isArray(images) || !images.length) {
    //TODO find realy placeholders
    return [];
    // return [
    //   "https://magiccityfilmmakers.s3.us-east-2.amazonaws.com/62a2b18926b09cb95fc72e99/uploads/inventory/s/84d4506f-03fe-4cf6-80a0-08b199095595.jpeg",
    //   "https://magiccityfilmmakers.s3.us-east-2.amazonaws.com/62a2b18926b09cb95fc72e99/uploads/inventory/s/84d4506f-03fe-4cf6-80a0-08b199095595.jpeg",
    //   "https://magiccityfilmmakers.s3.us-east-2.amazonaws.com/62a2b18926b09cb95fc72e99/uploads/inventory/s/84d4506f-03fe-4cf6-80a0-08b199095595.jpeg",
    //   "https://magiccityfilmmakers.s3.us-east-2.amazonaws.com/62a2b18926b09cb95fc72e99/uploads/inventory/s/84d4506f-03fe-4cf6-80a0-08b199095595.jpeg",
    //   // "https://magiccityfilmmakers.s3.us-east-2.amazonaws.com/62a2b18926b09cb95fc72e99/uploads/inventory/s/84d4506f-03fe-4cf6-80a0-08b199095595.jpeg",
    // ];
  }
  return images.slice(0, 4).map((i) => `${STATIC_STORAGE_URL}/${i.url.s}`);
};
