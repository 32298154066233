import { Routes, Route } from "react-router-dom";
import { UsersList } from "views/Users/List";
import { UserDetail } from "views/Users/Detail";
// import { Home } from "views/Home";
import { WebDesigner } from "views/WebDesigner";
import { EmailTester } from "views/EmailTester";
import { Permissions } from "views/Users/Permissions";
import { AuthType } from "hooks/useUserAuth";
import { FC } from "react";
import { LoginForm } from "components/accounts/LoginForm";
import { SignUpForm } from "components/accounts/SignUpForm";
import { Gallery } from "views/Gallery/manage";
import { GalleryList } from "views/Gallery/list";
import { CreateNewGallery } from "views/Gallery/create";
import { AccountManagement } from "views/Account";
import { Subscription } from "views/Subscription";

export const PageNotFound = () => <h1>Not Found</h1>;

export const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<GalleryList />} />
    <Route path="/account" element={<AccountManagement />} />
    <Route path="/users" element={<UsersList />} />
    <Route path="/site/:userId" element={<WebDesigner />} />
    <Route path="/users/:id" element={<UserDetail />} />
    <Route path="/email" element={<EmailTester />} />
    <Route path="/permissions" element={<Permissions />} />
    <Route path="/gallery" element={<GalleryList />} />
    <Route path="/gallery/new" element={<CreateNewGallery />} />
    <Route path="/gallery/:galleryId" element={<Gallery />} />
    <Route path="/gallery/:galleryId/details" element={<Gallery />} />
    <Route path="/gallery/:galleryId/share" element={<Gallery />} />
    <Route path="/gallery/:galleryId/image/:imgId" element={<Gallery />} />
    <Route path="/gallery/details/:galleryId" element={<GalleryList />} />
    <Route path="/gallery/share/:galleryId" element={<GalleryList />} />
    <Route path="/subscription" element={<Subscription />} />
    <Route path="*" element={<PageNotFound />} />
  </Routes>
);

type UnathenticatedRoutesProps = {
  auth: AuthType;
};

export const UnathenticatedRoutes: FC<UnathenticatedRoutesProps> = ({
  auth,
}) => (
  <Routes>
    <Route path="/" element={<LoginForm auth={auth} />} />
    <Route path="/signin" element={<LoginForm auth={auth} />} />
    <Route path="/registration" element={<SignUpForm />} />
    <Route path="/recoverpassword" element={<LoginForm auth={auth} />} />
    <Route path="*" element={<LoginForm auth={auth} />} />
  </Routes>
);
