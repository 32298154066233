import React, { FC, ReactElement, useState } from "react";
import {
  CloudUploadOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Drawer, Dropdown, Popover, Space, Spin } from "antd";
import { Link } from "react-router-dom";

type ActionsButtonProps = {
  icon: ReactElement;
  onClick?: (e: any) => void;
};

const ActionsButton: FC<ActionsButtonProps> = ({ children, icon, ...rest }) => {
  return (
    <Button
      className="gallery-item-actions"
      type="link"
      size="small"
      icon={icon}
      {...rest}
    >
      {children}
    </Button>
  );
};

type GalleryActionItemsProps = {
  galleryId: string;
  handleDelete: (id: string) => void;
  isDeletePending: boolean;
};

export const GalleryActionItems: React.FC<GalleryActionItemsProps> = ({
  galleryId,
  handleDelete,
  isDeletePending,
}) => {
  const [isDeleteGalleryPopoverOpen, setIsDeleteGalleryPopoverOpen] =
    useState(false);

  const handleDeleteClick = () => {
    setIsDeleteGalleryPopoverOpen(false);
    handleDelete(galleryId);
  };

  const closeDeleteDrawer = () => {
    setIsDeleteGalleryPopoverOpen(false);
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <Link to={`/gallery/details/${galleryId}`}>
          <ActionsButton icon={<EditOutlined />}>Settings</ActionsButton>
        </Link>
      ),
      key: "0",
    },
    {
      label: (
        <Link to={`/gallery/share/${galleryId}`}>
          <ActionsButton icon={<ShareAltOutlined />}>Share</ActionsButton>
        </Link>
      ),
      key: "1",
    },
    {
      label: (
        <Link to={`/gallery/${galleryId}`}>
          <ActionsButton icon={<CloudUploadOutlined />}>
            Upload Photos
          </ActionsButton>
        </Link>
      ),
      key: "2",
    },
    {
      type: "divider",
    },
    {
      label: (
        <>
          <ActionsButton
            icon={<DeleteOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              setIsDeleteGalleryPopoverOpen(true);
            }}
          >
            Delete
          </ActionsButton>
        </>
      ),
      key: "3",
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }} trigger={["click"]}>
        <a
          onClick={(e) => e.preventDefault()}
          role="button"
          aria-label="Action menu"
        >
          <Space>
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>

      <Drawer
        title="This action can't be undone. Are you sure?"
        open={isDeleteGalleryPopoverOpen}
        destroyOnClose
        onClick={closeDeleteDrawer}
      >
        <Button
          danger
          size="small"
          icon={<DeleteOutlined />}
          onClick={handleDeleteClick}
        >
          {isDeletePending ? <Spin /> : "Confirm"}
        </Button>
      </Drawer>
    </>
  );
};

export default GalleryActionItems;
